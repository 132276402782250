.director-container{
    font-family: 'Work Sans', sans-serif;
    line-height: 25px;
    

    .director-article{
        margin: 35px 0px;

        .opening-message{
            margin: 0px 0px 25px 0px;
        }
        .director-image{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin: 0px 25px 0px 0px;

            img{
                width: 100%;
                height: 100%;
                // object-fit: contain;
            }
        }

        .director-closing{
            margin: 25px 0px 0px 0px;
            display: flex;
            align-items: flex-end;

            .director-info{
                list-style: none;

                .bold{
                    font-weight: 600;
                }
            }
        }
        
    }
}