.overview{
    font-family: 'Montserrat', sans-serif;
    
    .overview-header{
        margin: 100px 0px 0px 0px;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        align-items: center;

        .caret{
            margin: 0px 0px 0px 20px;
        }
    }

    .philosophy-header{
        margin: 10px 0px 20px 0px;
    }

    p{
        line-height: 27.5px;
        font-size: 14px;
    
    }

    .overview-description{
        margin: 25px 0px;
    }

    .overview-sub-header{
        font-size: 16px;
        font-weight: 600;
    }

    .ftr{
        font-size: 14px;
        margin: 0px 0px 20px 0px;
        list-style: none;
        display: flex;
        align-items: center;

      

        span{
            // margin: 0px 10px 0px 0px;
            font-weight: 600;
        }
    }
}

.subject-content{
    margin: 100px 0px 65px 0px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;

    .subject-container-header{
        margin: 0px 0px 35px 0px;
        font-size: 20px;
        font-weight: 600;
        color: rgba(120, 84, 247, 1);
    }

    .subject-container{
        display: flex;
        justify-content: space-between;
        margin: 0px 0px 65px 0px;

        .subject{
            // min-width: 25%;
            // max-width: 31%;
            // min-height: 300px;
            border: 1px solid rgba(0, 0, 0, 0.25);
            box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            // overflow: hidden;

            .subject-image-container{
                background: rgba(242, 242, 242, 1);
                padding: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
            }

            .subject-text-container{
                padding: 20px 15px;

                .subject-header{
                    font-weight: 700;
                    font-size: 16px;
                    margin: 5px 0px 15px 0px;
                }

                .subject-description{
                    font-size: 13px;
                    line-height: 20px;
                }
            }
        }

        // .subject:hover{
        //     transform: scale(1.02);
        //     transition: ease-in-out .2s;
        // }

    }
}

.subject.styled-subject{
    position: relative;

    .hand-print{
        position: absolute;
        top: -85px;
        right: -40px;
        z-index: -1;
        width: 100px;
    }
    
}



@media (min-width: 820px) {
    .subject{
      width: 31%;
    }

    .ellipse{
        margin: 0px 15px 0px 0px;
    }

    .ftr{
        span{
            margin: 0px 10px 0px 0px;
        }
    }
}



@media (max-width: 820px) {
   .subject-container{
    flex-direction: column;

    .subject{
        margin: 0px 0px 35px 0px;
    }
   }

   .ftr{
    flex-direction: column;
    text-align: center;

    span{
        margin: 0px 0px 20px 0px;
    }
}

.ellipse{
    margin: 0px 0px 15px 0px;
}

// .overview-sub-header{
//     text-align: center;
// }

.features{
    margin: 75px 0px 0px 0px;
}

.hand-print{
    display: none;
}
}
