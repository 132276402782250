.admission-steps-section{
    font-family: 'Montserrat', sans-serif;

    .admission-header{
        text-align: center;
        margin: 50px 0px 35px 0px;
        .main-header{
        
            font-weight: 600;
            margin: 0px 0px 20px 0px;
        }
        .instruction{
            font-weight: 500;
        }
    }

    .application-button-container{
        display: flex;
        width: 100%;
        margin: 0px 0px 25px 0px;

        .application-button{
            background: rgb(120, 84, 247);
         
            font-size: 15px;
            border-radius: 5px;
            padding: 15px 35px;
            margin-left: auto;
            border: none;
            font-weight: 500;
            font-family: 'Work Sans', sans-serif;
            cursor: pointer;
            margin-left:  auto;
            border: 1px solid transparent;
  

            a{
                color: #fff;
                text-decoration: none;
            }
        }

        .application-button:hover{
            background: none;
            border: 1px solid rgb(120, 84, 247);
         
        }

        .application-button:hover{
            a{
                color: rgb(120, 84, 247);
            }
        }
    }


    .steps-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .step{
            // background: ;
            background: rgba(120, 84, 247, 0.05);
            padding: 15px;
            margin: 25px 0px;
            border-radius: 5px;
            min-height: 250px;

            .step-header-content{
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight:600;
                margin: 0px 0px 20px 0px;

                .step-image{
                    width: 40px;
                    height: 40px;
                    margin: 0px 10px 0px 0px;
                }
            }

            p{
                font-size: 14px;
                line-height: 25px;
                font-weight: 500;
            }
        }
    }
}

@media (max-width: 720px) {
    .steps-container{
        flex-direction: column;
    }
    .main-header{
        font-size: 24px;
    }
}


@media (min-width: 720px) {
    .steps-container{
        .step{
            width: 47%;
        }
    }

    .main-header{
        font-size: 34px;
    }
}