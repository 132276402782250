@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

*{
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}


@keyframes slide {
    0%{
      transform: translateX(-20px);
      opacity: 0;
    }
  
    100%{
      transform: translateX(0);
      opacity: 1;
    }
  }



.parent-review{
     position: relative;
     display: flex;
 }
.parent-review-container{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 3rem;
    overflow: hidden;
}


.slider-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.slider-container p{
    margin-bottom: 1rem;
    line-height: 28px;
}

.slider-container p:nth-child(2){
    max-width: 95%;
    text-align: center;
    font-family: 'Lexend', sans-serif;
}

.parent-image{
    width: 7.5em;
    height: 7.5em;
}
.parent-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.parent-review-text{
    font-size: 1rem;
    animation: slide ease-in-out .4s;
    color: rgba(0, 0, 0, 0.85);
}
.parent-name{
    font-family: 'Lexend', sans-serif;
    font-weight: bold;
    
}
.buttonslider{
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 11rem;
    right: 0px;
}
.buttonslider-icon{
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

}
.buttonslider-icon svg{
    font-size: 1.5rem;
    text-align: center;
    cursor: pointer;
    color: rgb(142, 45, 234);
}
.parent-review-slide{
    display: none;
    width: 0;
}
.slide-active{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-dots{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 45%;
    bottom:2rem;
}
.dot{
    width: 1rem;
    height: 1rem;
    background: #ffffff;
    border-radius: 50%;
    margin-right: .5rem;
    border: 1px solid;
}
.dot-active{
    background:  rgba(142, 45, 234, 0.6);
    border: 1px solid white;
    cursor: pointer;
}


















@media screen and (min-width: 350px) and (max-width: 750px) {
    .parent-review{
        position: relative;
        display: flex;
    }
    .parent-review-container{
        width: 100%;
        padding: 2rem;

    }


    .slider-container{
        width: 100%;
        display: flex;
        height: 100%;
    }
    .slider-container p{
        margin-bottom: 15px;
        line-height: 17px;
    }

    .slider-container p:nth-child(2){
        max-width: 95%;
        text-align: center;
        font-family: 'Lexend', sans-serif;
    }

    .parent-image{
        width: 7em;
        height: 7em;
    }
    .parent-image img{
        width: 100%;
        height: 100%;
    }
    .parent-review-text{
        font-size: 12px;
        animation: slide ease-in-out .4s;
        color: rgba(0, 0, 0, 0.85);
    }
    .parent-name{
        font-weight: bold;
    }
    .buttonslider{
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 12rem;
        right: 0px;
    }
    .buttonslider-icon{
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

    }
    .buttonslider-icon svg{
        font-size: 1.5rem;
        text-align: center;
        cursor: pointer;
        color: rgb(142, 45, 234);
    }
    .parent-review-slide{
        display: none;
        width: 0;
    }
    .slide-active{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container-dots{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 45%;
        bottom:2rem;
    }
    .dot{
        width: 1rem;
        height: 1rem;
        background: #ffffff;
        border-radius: 50%;
        margin-right: .5rem;
        border: 1px solid;
    }
    .dot-active{
        background:  rgba(142, 45, 234, 0.6);
        border: 1px solid white;
        cursor: pointer;
    }
}
