.slideshow {
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    margin: 0px 0px 50px 0px;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  
}

.slide-background {
    height: 100%;
}

.slide {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
}

.slide img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.navigation-emblem{

    /* img{
        width: 70px;
        height: 70px;

        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 5;
    }
     */
}

.slide-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
 
}

.slide-banner {
   

    color: #fff;
}

.slide-banner .slide-text {
    max-width: 600px;
    font-size: 34px;
    word-break: break-word;
    white-space: pre-line;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    line-height: 40px;
}

.slide-banner .slide-text-description {
    font-size: 15px;
    margin: 10px 0px 20px 0px;
    font-family: 'DM Sans', sans-serif;
    word-break: break-word;
    white-space: pre-line;
}

@media screen and (min-width: 350px) and (max-width: 500px) {
    .slide-banner {
        font-size: 20px;
       
        height: auto; /* Set auto to let content adjust height */
    }
    
 
}

@media (min-width: 720px) {
    .slideshowSlider{
        height: 100vh
    }

    .slide-overlay{
        padding: 0 50px 0 50px;
    }
}

@media (max-width: 720px) {
    .slideshowSlider{
        height: 600px;
    }

    .slide-overlay{
        padding: 0 20px 0 20px;
    }
}


@media (min-width: 720px) {
    .slider-page-width{
        width: calc(100% - 4rem);
        margin: 0 auto;
    }
}