// @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

.contact-us-banner{
  width: 100%;
  height: 105vh;


  position: relative;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.contact-us-container {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  padding: 50px 25px;
  // font-family: "Lexend", sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  line-height: 25px;
  font-family: 'Work Sans', sans-serif;
}
// .contact-page-width{
//   width: calc(100% - 12rem);
//   padding: 50px 20px;
//   margin: 0 auto;
// }
.contact-details {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #ccc;
}
.contact-details-part-one{
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.contact-details h2 {
  font-size: 24px;
  color: #8E82FF;
  margin: 0px 0px 25px 0px;
}

// .contact-details h3 {
//   color: #d10032;
// }

.social-icons a {
  margin-right: 10px;
  font-size: 20px;
  color: #ccc;
}

.get-in-touch {
  width: 45%;
}

.contact-us-form{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.get-in-touch h2 {
  font-size: 24px;
  color: #8E82FF;
}

.get-in-touch form {
  display: flex;
  flex-direction: column;
}
.cuf{
  display: flex;
  flex-direction: column;

}

.br{
  margin: 15px 0px;
}
.get-in-touch label {
  margin-top: 15px;
  font-weight: bold;
  color: #ccc;
}

.get-in-touch input,
.get-in-touch textarea {
  padding: 15px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  border: none;
  outline: 0;
  resize: none;
  font-family: 'Work Sans', sans-serif;
  height: auto;
}

.get-in-touch button {
  margin-top: 20px;
  padding: 15px;
  background-color: #8E82FF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
}

.get-in-touch button:hover {
  background-color: #8E82FF;
}
@media screen and (min-width:350px) and (max-width: 750px) {



  .contact-us-banner{
    width: 100%;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .contact-us-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 20px;

  }
  .contact-page-width{
    width: calc(100% - 1rem);
    padding: 10px 15px;
    margin: 0 auto;
  }
  .contact-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .contact-details-part-one{
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .contact-details h2 {
    font-size: 24px;
  }

  .contact-details h3 {
    // color: #d10032;
  }

  .social-icons a {
    margin-right: 10px;
    font-size: 20px;
  }

  .get-in-touch {
    width: 100%;
  }

  .contact-us-form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
  .get-in-touch h2 {
    font-size: 24px;
  }

  .get-in-touch form {
    display: flex;
    flex-direction: column;
  }
  .cuf{
    display: flex;
    flex-direction: column;

  }
  .get-in-touch label {
    margin-top: 10px;
    font-weight: bold;
  }

  .get-in-touch input,
  .get-in-touch textarea {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .get-in-touch button {
    margin-top: 20px;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .get-in-touch button:hover {
    background-color: #8E82FF;
  }
}