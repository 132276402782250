@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Urbanist:ital,wght@0,100..900;1,100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

/* body{
  /* font-family: 'Lexend', sans-serif; */
body.navigation-list.active{
     overflow: hidden;
  
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
