.school-objectives{
        
    .objective{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin: 35px 0px 35px 0px;
        font-family: 'Urbanist', sans-serif;

        .objective-content{
            // width: 55%;
            font-weight: 500;
            font-size: 15px;

            .missions{
                list-style: none;
                line-height: 35px;

                .mission{
                    display: flex;
                    align-items: center;

                    svg{
                        margin: 0px 15px 0px 0px;
                        color: rgb(107,99,255);
                    }
                }
            }

            .objective-header{
                font-weight: 600;
                font-size: 24px;
                font-family: 'Montserrat', sans-serif;
                color: rgb(107,99,255);
            }

            .objective-description{
                margin: 25px 0px;
                line-height: 30px;
            }

         
        }

        .objective-image{
            // width: 35%;
            // height: 600px;
            border-radius: 10px;
          
            overflow: hidden;
        }
    }

    .vision-container{
        flex-direction: row-reverse;
        // margin: 35px 0px 0px 0px;
        position: relative;

        .chalkboard-image{
            position: absolute;
            top: 50%;
            left: 90%;
            transform: translateX(-90%);
            width: 200px;
            height: 200px;
            z-index: -1;
        }

     
    }

    .mission-container{
        position: relative;

        
        .h-image{
            position: absolute;
            bottom: 0;
            left: 45%;
            transform: translateX(-45%);
            width: 150px;
            height: 150px;
            z-index: -1;
        }

        .four-image{
            position: absolute;
            bottom: 0;
            left: 40%;
            transform: translateX(-40%);
            width: 150px;
            height: 150px;
            z-index: -1;
        }


      
        .lottie-fish{
            position: absolute;
            top: -20%;
            left: 100px;
        }
    }
}

.autoShow{
    animation: autoShowAnimation ease-in-out .7s both;
    animation-timeline: view(90% 5%);
}

@keyframes autoShowAnimation{
    from{
        opacity: 0;
        transform: translateY(200px) scale(0.5)
    }

    to{
        opacity: 100;
        transform: translateY(0) scale(1)
    }
}



@media (min-width: 720px) {
    .page-width{
        width: calc(100% - 4rem);
        padding: 15px 20px;
        margin: 0 auto;
    }

   
}

@media (max-width: 720px) {
 
    .page-width{
        width: calc(100% - 4rem);
        // padding: 15px 20px;
        margin: 0 auto;
    }

   
}

@media (max-width: 820px) {
    .objective-image{
        width: 100%;
        min-height: 400px;
        object-fit: cover;
        margin: 25px 0px 0px 0px;
        transition: ease-in-out .5s;
    }

    .objective{
        flex-direction: column;
    }
 
}

@media (min-width: 820px) {
    .objective-content{
        width: 55%;
    }

    .objective-image{
        width: 35%;
        min-height: 500px;
        object-fit: cover;;
       
    }
}