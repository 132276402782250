

.navigation-bar{
    position: relative;

    .accordion-toggle{
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 15;
    
    }

    img{
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        // z-index: 5;
        // margin-right: auto;
    }
}

// Navigation Bar
.navigation-content {
 
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
    // position: absolute;
    // height: 100vh;
    // transition: ease-in-out;
    // animation: slideIn 0.4s;

    @media (min-width: 1024px) {
        justify-content: right;
    }

    @media (max-width: 1024px) {
        justify-content: center;
    }

    @media (min-width: 720px) {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 7.5px 0;
    }

    @media (max-width: 720px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
       
        // background: green;
        z-index: 10;
 
    }

   
}

// Accordion button (visible below 720px)
.accordion-toggle {
    // display: none;
    background: none;
    border: none;
    font-size: 34px;
    font-weight: 800;
    cursor: pointer;
    padding: 10px 10px;
    // z-index: 5;
    color: #8E82FF;
    // background:rgba(120, 84, 247, 0.5);
    background: #FEFEFE;
    border: 2px solid rgba(120, 84, 247, 0.05);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    

    @media (max-width: 720px) {
        display: block; // Accordion button visible below 720px
    }
}

.navigation-list {
    display: flex;
    list-style: none;
    position: relative;
    z-index: 2;

    @media (max-width: 720px) {
        height: 100%;
        justify-content: center;
        transition: ease-in-out;
        animation: slideIn 0.5s;
    
    }

    li {
        font-weight: 600;
        cursor: pointer;
        position: relative;
        letter-spacing: 0.1px;

        @media (min-width: 1024px) {
            .nav-button {
                margin: 0 25px;
            }
        }

        @media (max-width: 1024px) {
            .nav-button {
                margin: 0 15px;
            }
        }

        @media (max-width: 720px) {
            .nav-button {
                text-transform: uppercase;
                font-size: 34px;
                font-weight: 800;
                // margin: 0px 0px 25px 0px;
            }

            .nav-button:hover{
                color: rgba(120, 84, 247, 0.5);
            }
        }

        @media (min-width: 720px) {
            .nav-button {
              
                font-size: 14px;
                font-weight: 600;
            }
        }

 

        .nav-button {
            background: none;
            font-family: 'Urbanist', sans-serif;
            border: none;
            text-decoration: none;
            color: rgb(0,0,0);
            cursor: pointer;

            a{
                text-decoration: none;
                color: rgb(0,0,0);
            }
        }
    }

    .dropdown-items {
        position: absolute;
        top: 21px;
        left: 20px;
        display: none;
        font-size: 13.5px;
        border-radius: 5px;
        border: 2px solid rgba(120, 84, 247, 0.05);
        width: 150px;
        transition: ease-in-out;
        animation: slideInItem .2s;
        overflow: hidden;
        // z-index: 1000;

       

        li {
            padding: 10px;
            cursor: pointer;
            list-style: none;
            background-color: rgb(255, 255, 255);

            a {
                text-decoration: none;
                color: #8E82FF;
            }
        }
    }
}

// Accordion functionality for screens below 720px
@media (max-width: 720px) {

   
    .navigation-list {
        display: none; // Hide navigation list by default
    
        // Show when the accordion is toggled
        transition: ease-out;
        animation: slideOut 0.5s;
    
        flex-direction: column;
      
        background-color: #fff; // White background for the dropdown
    
    }

    .navigation-list.active {
        display: flex; // Show when the accordion is toggled
        transition: ease-in-out;
        animation: slideIn 1.5s;
    }


    // Adjust individual list items for mobile view
    .main-list-items {
        text-align: center;
        margin: 15px 0;
        width: 100%; // Full width
    }

    .dropdown-items {
        position: absolute; // Ensure dropdown doesn't overflow
        width: 100%; // Full width for dropdown items
    }

    .dropdown-items li {
        width: 100%;
    }
}

// Keyframe for slide-in animation
@keyframes slideIn {
    0% {
        transform: translateY(-100vh);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes slideInItem {
    0% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}
@keyframes slideOut {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-100vh);
    }
}
// .main-list-items {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// }



@media (max-width: 720px) {
    .dropdown-items{
        display: none;
    }

    .page-width {
        width: calc(100% - 4rem);
        // padding: 15px 20px;
        margin: 0 auto;
    }

    .dropdown-items.active{
        display: none;
    }

    .accordion-toggle{
        display: flex;
    }

    .navigation-bar{
        img{
            display: none;
          
        }
    }

 
}

@media (min-width: 720px) {


 
    .page-width {
        width: calc(100% - 4rem);
        padding: 15px 20px;
        margin: 0 auto;
    }

    .accordion-toggle{
        display: none;
    }

    .main-list-items {
 

        // Dropdown initially hidden
        .dropdown-items {
            display: none;
        }
    
        // Show dropdown when hovering over main-list-items
      .dropdown-items.active {
            display: block;
         
        }
    }
    
}

