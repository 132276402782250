.value-section{
    margin: 35px 0px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;

    .value-section-header{
        font-size: 24px;
        font-weight: 700;
        margin: 0px 0px 35px 0px;
    }
   
    .value-container{
        display: flex;
        // flex-direction: column;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 20px;
        
        .value{
            padding: 35px 15px;
            border-radius: 10px;
            // background: pink;
            flex: 0 0 auto;
     
          
            text-align: left;
            color: rgb(0,0,0);
            background: rgba(120, 84, 247, 0.1);
            border: 2px solid rgba(120, 84, 247, 0.05);

        
            .value-header{
                margin: 0px 0px 20px 0px;
                font-weight: 600;
            }

            p{
                font-size: 14px;
                line-height: 25px;
            }
        }
    }

  
}



@media (min-width: 720px) {
  
    .value{
        width: 31%;
    }

    .page-width{
        width: calc(100% - 4rem);
        padding: 15px 20px;
        margin: 0 auto;
    }
}

@media (max-width: 720px) {
    .value{
        width: 100%;
    }

    .value:nth-child(2){
        margin: 15px 0px;
    }

    .value-container{
        flex-direction: column;
    }

    .page-width{
        width: calc(100% - 4rem);
        // padding: 15px 20px;
        margin: 0 auto;
    }
}

