.gallery-section{
  
    .gallery-container{
        display: flex;
        justify-content: space-between;
        // height: calc(100vh - 5rem);
        height: 475px;
        margin: 75px 0px;
     

        .gallery{
            width: 32.9%;
          
            // border-radius: 15px;
            height: 100%;     
          
        }

        .gallery.initial{
            flex-direction: column;
            justify-content: space-between;
              display: flex; 
            
            .gallery-item-header{
                width: 100%;
              
    
                // background: rgba(0,0,0,0.1);
                font-family: 'Montserrat', sans-serif;

                header{
                    font-weight: 600;
                  
                    margin: 0px 0px 10px 0px;
                }

                p{
                   
              
                    font-family: 'Work Sans', sans-serif;
                    font-weight: 500;
                }
            }

            .gallery-content{
                width: 100%;
                height: 75%;
                background: rgba(0,0,0,0.1);
            }
        }

        .gallery.central{
            background: rgba(0,0,0,0.1);
        }

        .gallery.final{
            flex-direction: column;
            justify-content: space-between;
            display: flex; 
            
            .gallery-item-header{
                width: 100%;
                height: 49.5%;
                background: rgba(0,0,0,0.1);      
            }

            .gallery-content{
                width: 100%;
                height: 49.5%;
                background: rgba(0,0,0,0.1);
            }
        }
    }
}

@media (min-width: 1024px) {
    .gallery.initial{
        .gallery-item-header{
            padding: 10px 10px;
            height: 20%;
    
            header{
                font-size:24px;
            }
    
            p{
                line-height: 25px;
                font-size: 12px;
            }
        }
    }
   
}


@media (max-width: 1024px) {
   .gallery.initial{
     .gallery-item-header{
        height: 25%;
        header{
            font-size: 14px;
        }
        // padding: 0px 0px 5px 0px;
        p{
            font-size: 9.5px;
        }
    }
   }
}