$navigationTextSize : 15px;
$textSpacing : 1px;
$purple: rgba(120, 84, 247, 1);


.button{

    font-size: $navigationTextSize;
    font-weight: 400;
    letter-spacing: $textSpacing;
    padding: 7.5px 45px;
    outline: 0;
    border-radius: 30px;
    cursor: pointer;
  
    background: $purple;
    border: 1.5px solid transparent;
    display: flex;
    align-items: center;
    text-align: center;
    font-family: 'DM Sans', sans-serif;

    a{
        text-decoration: none;
        color: #fff;
    }
    
    
    svg{
        margin-left: 5px;
        color: yellow;  
        
    }
}

.button:hover svg{
    transform: rotate(90deg);
    transition: ease-in-out .2s;
}

/*
.button:hover{
    background: transparent;
    color: $purple;
    border: 1.5px solid $purple;
}*/
@media screen and (min-width :350px) and (max-width : 720px) {
    .button{
        font-size: 14px;
        padding: 10px 25px;
    }
}
